import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Link, Route, Routes} from "react-router-dom";
import {Navbar, Nav, Container} from "react-bootstrap";
import Impressum from "./Impressum";
import Home from "./Home";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsConditions from "./TermsConditions";

function App() {
    return (
        <Router>
            <Navbar style={{background: "#a60505", fontSize: "20px", fontWeight: "bold", color: 'white'}} expand={"lg"} >
                <Container>
                    <Navbar.Brand>
                        <h1 style={{color: "white"}}>VinoVista</h1>
                    </Navbar.Brand>
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/" style={{color: "white"}}>
                            Home
                        </Nav.Link>
                        <Nav.Link as={Link} to="/impressum" style={{color: "white"}}>
                            Impressum
                        </Nav.Link>
                        <Nav.Link as={Link} to="/privacy" style={{color: "white"}}>
                            Privacy
                        </Nav.Link>
                        <Nav.Link as={Link} to="/termsandconditions" style={{color: "white"}}>
                            Terms & Conditions
                        </Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
            <Routes>
                <Route path="/" element={<Home />}/>
                <Route path="/impressum" element={<Impressum/>}/>
                <Route path="/privacy" element={<PrivacyPolicy/>}/>
                <Route path="/termsandconditions" element={<TermsConditions/>}/>
                {/*<Route path="*" element={<NotFound />} />*/}
            </Routes>
        </Router>
    );
}

export default App;
