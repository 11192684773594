import {Container} from "react-bootstrap";

export default function Impressum() {
    return (
        <Container>
            <h1 style={{marginTop: 20}}>Impressum</h1>
            <h3 style={{marginTop: 40}}>Betreiber der Website</h3>
            <h4 style={{marginTop: 10}}>Name und Sitz des Einzelunternehmens</h4>
            <p style={{margin: 0}}>VinoVista e.U.</p>
            <p style={{margin: 0}}>Mühlhofstraße 5</p>
            <p style={{margin: 0}}>3500 Krems an der Donau, Österreich</p>
            <p style={{margin: 0}}>Inhaber: Dominik Hofmann</p>
            <p style={{margin: 0}}>Email: vinovista.official@gmail.com</p>
            <p style={{margin: 0}}>Tel: +43 676 7306561</p>

            <h5 style={{marginTop: 40}}>Firmenbuchnummer</h5>
            <p style={{margin: 0}}>628391 f</p>
            <h5 style={{marginTop: 40}}>Firmenbuchgericht</h5>
            <p style={{margin: 0}}>Landesgericht Krems an der Donau</p>

            {/*<h5 style={{marginTop: 40}}>Tätigkeitsbereich</h5>*/}
            {/*<p style={{margin: 0}}>Erbringung von Dienstleistungen in der automatischen Datenverarbeitung und Informationstechnik</p>*/}

            <h4 style={{marginTop: 40}}>Haftungsauschluss</h4>
            <p style={{margin: 0}}>Im Hinblick auf die technischen Eigenschaften des Internet kann keine Gewähr für die
                Authentizität, Richtigkeit und Vollständigkeit der im Internet zur Verfügung gestellten Informationen
                übernommen werden. Es wird auch keine Gewähr für die Verfügbarkeit oder den Betrieb der gegenständlichen
                Homepage und ihrer Inhalte übernommen.<br />
                <br />
                Jede Haftung für unmittelbare, mittelbare oder sonstige Schäden, unabhängig von deren Ursachen, die aus
                der Benutzung oder Nichtverfügbarkeit der Daten und Informationen dieser Homepage erwachsen, wird,
                soweit rechtlich zulässig, ausgeschlossen.<br />
                <br />
                Der Inhalt dieser Homepage ist urheberrechtlich geschützt. Die Informationen sind nur für die
                persönliche Verwendung bestimmt. Jede weitergehende Nutzung insbesondere die Speicherung in Datenbanken,
                Vervielfältigung und jede Form von gewerblicher Nutzung sowie die Weitergabe an Dritte - auch in Teilen
                oder in überarbeiteter Form - ohne Zustimmung der jeweiligen Organisation ist untersagt.</p>
        </Container>
    )
}
